const style = `
.engage-tour-container,
.engage-tour-container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  text-align: initial;
  line-height: 1.4;
}
.engage-tour-modal {
  position: relative
}
.engage-tour-container_modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
.engage-tour-container_modal.et-hidden {
  display:none
}
.engage-tour-container iframe {
  border: 0;
  width: 100%;
  height: 100vh;
  position:fixed;
  bottom:0;
  left:0;
  z-index: 999999;
}
.engage-tour-container iframe.et-minimized {
  height: 52px;
}
.engage-tour-container iframe.et-hidden {
  display:none
}
#engage-tour-highlight {
  background-color:#105DFB !important;
  outline:1px dashed #cf3131;
  opacity:0.4;
  position:absolute;
  pointer-events: none;
  z-index: 99999;
}
.engage-tour-selected {
  z-index: 999998;
  outline: 2px solid #275efe;
  border-radius: 2px;
  position: relative;  
}
.engage-tour-selected.no-highlight {
  outline: 0
}
.engage-tour-selected.no-pointer {
  pointer-events: none;
}
.engage-tour-logo-btn {
  width: auto;
  z-index: 1000;
  position: fixed;
  bottom: 0px;
  left: 0px;
  margin: 0 0 0.3rem 0.3rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  transition: background-color 0.5s ease 0s;
  display: inline-block;
  border-radius: 7px;
  border: 1px solid #fff;
  color: #323233;
  white-space: nowrap;
  background-color: #6f34e7;
  padding: 4px 4px 0px;
}
.engage-tour-cursor a, .engage-tour-cursor div, .engage-tour-cursor span, .engage-tour-cursor img, .engage-tour-cursor i, .engage-tour-cursor button {
  cursor: default !important
}
.engage-tour-cursor:active {
  pointer-events: none;
}
.engage-tour-preview {
background-color: transparent;
padding: 0;
margin: 0;
border: 0;
}
.engage-tour-tooltip {
  z-index: 999999999;
  position: absolute;
  width: 280px;
  background: transparent;
}
.engage-tour-preview .close {
  top: 3px;
  right: 2px;
  z-index: 1;
  display: block;
  color: #777;
  position: absolute;
  border-radius: 50%;
  padding: 3px;
  background-color: #ffffffed;
}
.engage-tour-preview .close:hover {
  color: #222
}
.engage-tour-tooltip svg {
  display: block;
}
.engage-tour-tooltip-body {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
  box-shadow: #64646f33 0 7px 29px;
  border: 1px solid #e7e7e7;
  height: calc(100% - 12px);
  width: calc(100% - 12px);
  top: 6px;
  left: 6px;
}
.engage-tour-tooltip-body:before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-width: 12px;
  border-style: solid;
}
.engage-tour-tooltip-body:after {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-width: 12px;
  border-style: solid;
}
.engage-tour-tooltip-body.placement-top:before {
  border-color: transparent;
  bottom: -24px;
  left: 12px;
}
.engage-tour-tooltip-body.placement-top:after {
  border-color: var(--bg-color, #fff) transparent transparent transparent;
  bottom: -23px;
  left: 12px;
}
.engage-tour-tooltip-body.placement-bottom:before,
.engage-tour-tooltip-body.placement-auto:before {
  border-color: transparent;
  top: -24px;
  left: 12px;
}
.engage-tour-tooltip-body.placement-bottom:after,
.engage-tour-tooltip-body.placement-auto:after {
  border-color: transparent transparent var(--bg-color, #fff) transparent;
  top: -23px;
  left: 12px;
}
.engage-tour-tooltip-body.placement-right:before {
  border-color: transparent;
  left: -24px;
  top: 12px;
}
.engage-tour-tooltip-body.placement-right:after {
  border-color: transparent var(--bg-color, #fff) transparent transparent;
  left: -23px;
  top: 12px;
}
.engage-tour-tooltip-body.placement-left:before {
  border-color: transparent;
  right: -24px;
  top: 12px;
}
.engage-tour-tooltip-body.placement-left:after {
  border-color: transparent transparent transparent var(--bg-color, #fff);
  right: -23px;
  top: 12px;
}
.engage-tour-step-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  color: #8c94a6;
  background-color: #edf2fa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.engage-tour-step-footer button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.5s ease 0s;
  display: inline-block;
  border-radius: 7px;
  background-color: #275efe;
  border: 1px solid #275efe;
  color: #e9efff;
  padding: 3px 12px;
  white-space: nowrap;
}
.engage-tour-step-footer button:hover {
  color: #fff;
  border: 1px solid #2355e5;
  background-color: #2355e5;
}
.engage-tour-step-content {
  outline: 0;
}
.engage-tour-step-content h1,
.engage-tour-step-content h2,
.engage-tour-step-content h3,
.engage-tour-step-content p {
  margin: 0;
  padding: 6px 0;
  color: inherit;
  line-height: 1.4;
}
.engage-tour-step-content ul,
.engage-tour-step-content ol {
  padding: 0;
  margin: 6px 0 0 20px;
}
.engage-tour-step-content ul,
.engage-tour-step-content ol {
  padding: 0;
  margin: 6px 0 0 20px;
}
.engage-tour-step-content ul:first-child,
.engage-tour-step-content ol:first-child {
  margin-top: 0;
}
.engage-tour-step-content ul:last-child,
.engage-tour-step-content ol:last-child {
  margin-bottom: 0;
}
.engage-tour-step-content li p {
  padding: 2px 0;
}
.engage-tour-step-content h1:first-child,
.engage-tour-step-content h2:first-child,
.engage-tour-step-content h3:first-child,
.engage-tour-step-content p:first-child {
  margin-top: 0;
}
.engage-tour-step-content h1:last-child,
.engage-tour-step-content h2:last-child,
.engage-tour-step-content h3:last-child,
.engage-tour-step-content p:last-child {
  margin-bottom: 0;
}
`

export default style
