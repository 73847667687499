import { Engage, key, userProps } from '../engage'
import { $, getBgColor } from '../libs/docs'

let tourChannel
let tourFramePort
let scrollTimeout
let scrollEl
let bodyOverflow

const tourProps = {}
const tourAction = window.sessionStorage.getItem('engage_tour_action')

const closeEditor = () => {
  window.sessionStorage.removeItem('engage_tour')
  window.sessionStorage.removeItem('engage_tour_action')
  window.sessionStorage.removeItem('engage_tour_next')
  tourProps.tourContainerDiv.remove()
}

const onTourAction = (v) => {
  if (v.url) {
    // No next? stop
    if (!v.mode) {
      stopTourPreview()
    }
    if (v.url !== window.location.href) {
      if (v.mode) {
        window.sessionStorage.setItem('engage_tour_action', JSON.stringify(v))
      }
      window.location.href = v.url
    } else {
      if (v.mode === 'preview') {
        tourPreview(v.index)
      }
    }
  } else if (v.click) {
    // Get element and trigger click
    const el = $(v.click)
    if (el) {
      if (v.mode) {
        window.sessionStorage.setItem('engage_tour_action', JSON.stringify(v))
      } else {
        stopTourPreview()
      }
      let refreshing = false
      function handleBeforeUnload () {
        refreshing = true
      }
      window.addEventListener('beforeunload', handleBeforeUnload)
      el.click()
      // Click may not redirect so if it doesnt, show next step
      if (v.mode) {
        setTimeout(() => {
          if (!refreshing) {
            window.sessionStorage.removeItem('engage_tour_action')
            window.removeEventListener('beforeunload', handleBeforeUnload)
            tourPreview(v.index)
          }
        }, 500)
      }
    } else {
      if (v.mode === 'preview') {
        // try show it instead of getting stuck
        tourPreview(v.index)
      }
    }
  } else {
    if (v.mode === 'preview') {
      // preview
      tourPreview(v.index)
    } else {
      tourFramePort.postMessage({
        action: JSON.stringify(v),
        type: 'tour_action'
      })
    }
  }
}

const getModalStyle = (step, theme) => {
  const style = {
    width: `${step.width}${step.width_px ? 'px' : '%'}`,
    'border-radius': `${step.corner}px`,
    color: theme.color || '#333',
    'background-color': theme.bg || '#fff',
    '--bg-color': theme.bg
  }

  const s = Object.keys(style)
    .map((k) => `${k}:${style[k]}`)
    .join(';')

  return { style: s }
}

const getTooltipStyle = (step, theme) => {
  let x = 10
  let y = 10
  if (step.br.x) {
    x = step.br.x
  }
  if (step.br.y) {
    y = step.br.y
  }

  const style = {}
  const bodyStyle = {
    width: `${step.width}px`,
    'border-radius': `${step.corner}px`,
    color: theme.color || '#333',
    'background-color': theme.bg || '#fff',
    '--bg-color': theme.bg
  }
  let placement = step.placement
  if (step.placement === 'auto') {
    if (x + step.br.width < window.innerWidth / 2) {
      placement = 'right'
    } else if (x > window.innerWidth / 2) {
      placement = 'left'
    } else if (y < window.innerHeight / 2) {
      placement = 'bottom'
    } else {
      placement = 'top'
    }
  }
  if (placement === 'top') {
    y = (window.innerHeight + 20) - (step.br.y + window.scrollY)
    // x += window.scrollX
    style.left = `${x}px`
    style.bottom = `${y}px`
  }
  if (placement === 'bottom') {
    y += window.scrollY + step.br.height + 6
    // x += window.scrollX
    style.left = `${x}px`
    style.top = `${y}px`
  }
  if (placement === 'left') {
    y += window.scrollY - 20
    x = window.innerWidth - step.br.x + 6
    // x = window.innerWidth + window.scrollX - step.br.x + 6
    style.right = `${x}px`
    style.top = `${y}px`
  }
  if (placement === 'right') {
    y += window.scrollY - 20
    x += step.br.width + 6
    // x += window.scrollX + step.br.width + 6
    style.left = `${x}px`
    style.top = `${y}px`
  }

  const s = Object.keys(style)
    .map((k) => `${k}:${style[k]}`)
    .join(';')
  const bs = Object.keys(bodyStyle)
    .map((k) => `${k}:${bodyStyle[k]}`)
    .join(';')

  return {
    style: s,
    bodyStyle: bs,
    placement
  }
}
const onScroll = () => {
  clearTimeout(scrollTimeout)
  scrollTimeout = setTimeout(function () {
    if (scrollEl) {
      const br = JSON.parse(JSON.stringify(scrollEl.getBoundingClientRect()))
      br.wHeight = window.innerHeight
      br.wWidth = window.innerWidth

      tourFramePort.postMessage({
        br,
        type: 'set_selector_pos'
      })
    }
  }, 100)
}
const addContent = ({
  step,
  theme,
  branding,
  stepIndex,
  totalSteps,
  nextLabel
}) => {
  // Create div
  const tourDiv = document.createElement('div')
  document.body.appendChild(tourDiv)

  const previewDiv = document.createElement('div')
  const bodyDiv = document.createElement('div')
  previewDiv.className = 'engage-tour-preview'
  if (step.type === 'tooltip') {
    const { style, bodyStyle, placement } = getTooltipStyle(step, theme)
    previewDiv.classList.add('engage-tour-tooltip')
    previewDiv.style.cssText = style
    bodyDiv.classList = `engage-tour-tooltip-body placement-${placement}`
    bodyDiv.style.cssText = bodyStyle
  } else if (step.type === 'modal') {
    const { style } = getModalStyle(step, theme)
    previewDiv.classList.add('engage-tour-modal')
    previewDiv.style.cssText = style
  }

  const closeLink = document.createElement('a')
  closeLink.addEventListener('click', (e) => {
    e.preventDefault()
    stopTourPreview({ dismissed: true, stepIndex })
  })
  closeLink.setAttribute('href', '#')
  closeLink.className = 'close'
  closeLink.innerHTML = '<svg style="width:14px;height:14px;cursor:pointer;display:block" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>'

  bodyDiv.append(closeLink)
  const contentDiv = document.createElement('div')
  contentDiv.classList.add('engage-tour-step-content')
  contentDiv.style.cssText = `padding:${step.padding}px`
  contentDiv.innerHTML = `<div>${step.html}</div>`
  bodyDiv.append(contentDiv)

  const footerDiv = document.createElement('div')
  footerDiv.classList.add('engage-tour-step-footer')
  footerDiv.style.cssText = `border-bottom-left-radius:${step.corner}px;border-bottom-right-radius:${step.corner}px;background-color:${theme.bg_nav};`
  const brandHTML = branding ? '<a href="http://engage.so/" target="_blank"><svg style="display:block;width:24px;height:24px;" width="24" height="24" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M74.7238 39.6656C74.7238 39.0201 74.7111 38.3746 74.6536 37.7485C73.575 23.3606 60.3891 12 44.2928 12C27.4754 12 13.8427 24.3933 13.8427 39.672C13.8427 51.407 21.8781 61.4378 33.2259 65.4657C36.5574 66.6534 38.3956 70.2939 37.3935 73.7214L36.7298 76C44.6183 71.7269 53.0366 67.8604 60.4465 62.7998C66.6119 58.6041 72.5155 52.4268 74.175 44.8617H46.1501C43.9546 49.4447 38.9253 52.6592 33.0727 52.6592C26.7797 52.6592 21.4249 48.9477 19.5421 43.8031C19.1911 42.8414 18.9486 41.8279 18.8464 40.7822H74.7111C74.7238 40.395 74.7238 40.0399 74.7238 39.6656ZM19.1145 36.9739C20.4739 31.0999 26.1989 26.6977 33.0727 26.6977C39.9465 26.6977 45.665 31.0935 47.0436 36.9739H19.1145Z" fill="#00cc74"/></svg></a>' : ''
  footerDiv.innerHTML = `${brandHTML}<div>${stepIndex}/${totalSteps}</div>`
  const nextBtn = document.createElement('button')
  nextBtn.style.cssText = `background-color:${theme.btn};border-color:${theme.btn};color:${theme.btn_txt};`
  nextBtn.innerText = nextLabel
  const hasNext = totalSteps > stepIndex
  nextBtn.addEventListener('click', () => {
    removeActiveStep()
    if (step.next.action === 'default') {
      // Default action
      if (hasNext) {
        tourPreview(stepIndex)
      } else {
        stopTourPreview()
      }
    } else if (step.next.action === 'url') {
      const v = {
        url: step.next.url
      }
      if (hasNext) {
        v.mode = 'preview'
        v.index = stepIndex
      }
      // Go to URL
      onTourAction(v)
    } else if (step.next.action === 'click') {
      const v = {
        click: step.element
      }
      if (hasNext) {
        v.mode = 'preview'
        v.index = stepIndex
      }
      onTourAction(v)
    }
  })
  footerDiv.append(nextBtn)
  bodyDiv.append(footerDiv)

  previewDiv.append(bodyDiv)
  tourDiv.append(previewDiv)

  if (step.type === 'tooltip') {
    document.body.appendChild(tourDiv)
    // Add event listener to element for click
    if (step.next.action === 'click') {
      const el = $(step.element)
      if (el) {
        el.addEventListener('click', () => {
          if (!hasNext) {
            // Track complete
            Engage.request('/campaigns/tours/' + tourProps.id + '/a?e=c&uid=' + userProps.uid)
            return true
          }
          window.sessionStorage.setItem('engage_tour_action', JSON.stringify({
            click: step.element,
            mode: 'preview',
            index: stepIndex
          }))
        })
      }
    }
  } else {
    tourProps.modalDiv.appendChild(tourDiv)
  }
}

const removeActiveStep = () => {
  removeHighlight()
  if ($('.engage-tour-preview')) {
    $('.engage-tour-preview').remove()
  }
}
const stopTourPreview = (arg = {}) => {
  // Add modal div back
  const { dismissed, stepIndex } = arg
  removeActiveStep()

  if (!tourProps.modalDiv.classList.contains('et-hidden')) {
    tourProps.modalDiv.classList.add('et-hidden')
  }

  document.body.style.overflow = bodyOverflow

  if (tourProps.tourAuthorMode) {
    tourProps.tourIframe.classList.remove('et-hidden')
    // Show editor
    tourFramePort.postMessage({
      type: 'stop_preview'
    })
    return
  }

  // Track completed
  let url = '/campaigns/tours/' + tourProps.id + '/a?e=c&uid=' + userProps.uid
  if (dismissed) {
    // Track dismissed otherwise
    url = '/campaigns/tours/' + tourProps.id + '/a?e=d&step=' + stepIndex + '&uid=' + userProps.uid
  }
  Engage.request(url)
  closeEditor()
}
const startTourPreview = () => {
  // Pending tour page not found?
  const i = window.sessionStorage.getItem('engage_tour_next')
  if (i) {
    window.sessionStorage.removeItem('engage_tour_next')
    return tourPreview(+i)
  }
  tourPreview(0)
}
const tourPreview = (i) => {
  // Get step
  let tour = window.sessionStorage.getItem('engage_tour')
  tour = JSON.parse(tour)
  if (!Array.isArray(tour.steps) || !tour.steps[i]) {
    stopTourPreview()
    return
  }

  const step = tour.steps[i]
  const theme = tour.theme

  const totalSteps = tour.steps.length
  const stepIndex = i + 1

  const nextLabel = () => {
    if (step.next.label) {
      return step.next.label
    }
    if (stepIndex === totalSteps) {
      return 'Done'
    }
    return 'Next'
  }
  const nl = nextLabel()

  function previewTooltip () {
    if (step.highlight) {
      highlightSelector(scrollEl, !step.backdrop)
    }
    // If next it's click, remove nopointer
    if (step.next.action === 'click') {
      scrollEl.classList.remove('no-pointer')
    }

    // Incase there is no need to scroll
    step.br = JSON.parse(JSON.stringify(scrollEl.getBoundingClientRect()))
    const onScrollPreview = () => {
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(function () {
        if (scrollEl && step) {
          step.br = JSON.parse(JSON.stringify(scrollEl.getBoundingClientRect()))
          addContent({
            step,
            theme,
            stepIndex,
            totalSteps,
            branding: tour.branding,
            nextLabel: nl
          })
        }
        window.removeEventListener('scroll', onScrollPreview)
      }, 100)
    }
    window.addEventListener('scroll', onScrollPreview)
    scrollEl.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
    // Incase no scroll
    onScrollPreview()

    // has backdrop, insert modal and highlight
    if (step.backdrop) {
      if (tourProps.modalDiv.classList.contains('et-hidden')) {
        tourProps.modalDiv.classList.remove('et-hidden')
      }
      document.body.style.overflow = 'hidden' // lock scrolling
    } else {
      if (!tourProps.modalDiv.classList.contains('et-hidden')) {
        tourProps.modalDiv.classList.add('et-hidden')
      }
      document.body.style.overflow = ''
    }
  }

  if (step.type === 'tooltip') {
    scrollEl = $(step.element)
    if (!scrollEl) {
      // element not found, dont show
      // wait a bit
      let ticks = 0
      const interval = setInterval(() => {
        scrollEl = $(step.element)
        if (scrollEl) {
          clearInterval(interval)
          previewTooltip()
          return
        }
        if (ticks === 50) {
          clearInterval(interval)
          if (i) {
            // If it's not first item, track index to show when found next
            window.sessionStorage.setItem('engage_tour_next', i)
          }
          return
        }
        ticks++
      }, 100)
      return
    }
    previewTooltip()
  } else if (step.type === 'modal') {
    if (tourProps.modalDiv.classList.contains('et-hidden')) {
      tourProps.modalDiv.classList.remove('et-hidden')
    }
    addContent({
      step,
      theme,
      stepIndex,
      totalSteps,
      branding: tour.branding,
      nextLabel: nl
    })
  }

  // Track opens
  if (!tourProps.tourAuthorMode) {
    Engage.request('/campaigns/tours/' + tourProps.id + '/a?e=o&step=' + stepIndex + '&uid=' + userProps.uid)
  }
}

const highlightSelector = (element, noBackdrop) => {
  element.classList.add('engage-tour-selected', 'no-pointer')
  const computedStyle = window.getComputedStyle(element)
  const color = computedStyle.backgroundColor
  if ((color === 'rgba(0, 0, 0, 0)') || (color === 'transparent')) {
    element.style.backgroundColor = getBgColor(element)
    element.classList.add('engage-tour-colored')
  }

  if (!noBackdrop) {
    tourProps.modalDiv.classList.remove('et-hidden')
  }
}
const removeHighlight = () => {
  if ($('.engage-tour-selected')) {
    const el = $('.engage-tour-selected')
    el.classList.remove('engage-tour-selected', 'no-pointer', 'no-highlight')
    if (!el.classList.length) {
      el.removeAttribute('class')
    }
  }
  if ($('.engage-tour-colored')) {
    const el = $('.engage-tour-colored')
    el.style.backgroundColor = ''
    if (!el.style.length) {
      el.removeAttribute('style')
    }
    el.classList.remove('engage-tour-colored')
    if (!el.classList.length) {
      el.removeAttribute('class')
    }
  }
}

const setTour = (data) => {
  const tour = {
    steps: data.steps,
    theme: data.theme,
    token: data.token,
    key,
    id: data.id,
    ts: new Date()
  }
  if (data.branding) {
    tour.branding = true
  }
  window.sessionStorage.setItem('engage_tour', JSON.stringify(tour))
  return tour
}

const selectorMode = () => {
  let selector = false
  $('body').classList.add('engage-tour-cursor')
  const highlight = document.createElement('div')
  highlight.id = 'engage-tour-highlight'
  $('body').appendChild(highlight)

  let prev
  document.body.addEventListener('mouseover', mouseHandler)
  document.body.addEventListener('mousedown', clickHandler)

  function preventListeners (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    e.stopPropagation()
  }

  function clickHandler (e) {
    preventListeners(e)

    const element = e.target
    selector = window.CssSelectorGenerator.getCssSelector(element)
    highlightSelector(element)
    if (tourProps.tourIframe.classList.contains('et-hidden')) {
      tourProps.tourIframe.classList.remove('et-hidden')
      document.body.style.overflow = bodyOverflow
    }

    const br = JSON.parse(JSON.stringify(element.getBoundingClientRect()))
    br.wHeight = window.innerHeight
    br.wWidth = window.innerWidth
    tourFramePort.postMessage({
      selector,
      br,
      type: 'set_selector'
    })

    // Clean up
    document.body.removeEventListener('mouseover', mouseHandler)
    document.body.removeEventListener('mousedown', clickHandler)
    // Hide highlight
    $('body').classList.remove('et-cursor', 'engage-tour-cursor')
    $('#engage-tour-highlight').remove()
  }

  function mouseHandler (e) {
    preventListeners(e)

    // Not body or previous target
    if (e.target.id === 'engage_tour_frame' || e.target === document.body || (prev && prev === e.target)) {
      return
    }

    if (e.target) {
      prev = e.target
      const bounds = prev.getBoundingClientRect()
      const x = bounds.x + window.scrollX
      const y = bounds.y + window.scrollY

      // highlight box
      highlight.style.cssText = `top:${y}px;left:${x}px;height:${bounds.height}px;width:${bounds.width}px`
    }
  }
}

const handlePortMessages = (e) => {
  const data = e.data
  // Tour widget
  if (data.action === 'tooltip_remove_backdrop') {
    if (!tourProps.modalDiv.classList.contains('et-hidden')) {
      tourProps.modalDiv.classList.add('et-hidden')
    }
    return
  }
  if (data.action === 'tooltip_add_backdrop') {
    if (tourProps.modalDiv.classList.contains('et-hidden')) {
      tourProps.modalDiv.classList.remove('et-hidden')
    }
    return
  }
  if (data.action === 'tooltip_remove_highlight') {
    const el = $('.engage-tour-selected')
    if (el) {
      el.classList.add('no-highlight')
    }
    return
  }
  if (data.action === 'tooltip_add_highlight') {
    const el = $('.engage-tour-selected')
    if (el && el.classList.contains('no-highlight')) {
      el.classList.remove('no-highlight')
    }
    return
  }
  if (data.action === 'tooltip_cancel_selector') {
    window.removeEventListener('scroll', onScroll)

    removeHighlight()

    // Remove overlay
    tourProps.modalDiv.classList.add('et-hidden')
    return
  }
  if (data.action === 'tour_save') {
    const params = JSON.parse(data.v)
    if (!params.token) {
      return
    }
    if (!Array.isArray(params.steps)) {
      return
    }
    // if (params.steps.length === 0) {
    //   return
    // }
    window.sessionStorage.setItem('engage_tour', data.v)
    Engage.request('/campaigns/tours/editor', {
      steps: params.steps,
      id: params.id,
      theme: params.theme,
      token: params.token
    }, 'PUT').then(r => {
      if (r.status) {
        tourFramePort.postMessage({
          type: 'saved'
        })
      }
    }).catch(e => {})
    return
  }
  if (data.action === 'tour_close') {
    closeEditor()
    return
  }
  if (data.action === 'tour_action') {
    onTourAction(JSON.parse(data.v))
    return
  }
  if (data.action === 'tour_hide') {
    tourProps.tourIframe.classList.add('et-hidden')
    bodyOverflow = document.body.style.overflow
    document.body.style.overflow = ''
    return
  }
  if (data.action === 'tour_minimize') {
    tourProps.tourIframe.classList.add('et-minimized')
    document.body.style.overflow = ''
    return
  }
  if (data.action === 'tour_maximize') {
    tourProps.tourIframe.classList.remove('et-minimized')
    document.body.style.overflow = 'hidden' // Lock scrolling
    return
  }
  if (data.action === 'tour_select') {
    if (tourProps.tourAuthorMode) {
      selectorMode()
    }
    return
  }
  // Called when editing or previewing a tooltip to focus tooltip pointer
  if (data.action === 'tour_reselect') {
    scrollEl = $(data.element)
    if (!scrollEl) {
      return
    }
    highlightSelector(scrollEl)
    // Incase there is no need to scroll
    const br = JSON.parse(JSON.stringify(scrollEl.getBoundingClientRect()))
    br.wHeight = window.innerHeight
    br.wWidth = window.innerWidth
    tourFramePort.postMessage({
      br,
      type: 'set_selector_pos'
    })
    window.addEventListener('scroll', onScroll)
    scrollEl.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
}

const addTourFrame = ({
  tour,
  previewMode
}) => {
  tourProps.tourIframe.addEventListener('load', () => {
    if (!tourChannel) {
      tourChannel = new MessageChannel()
    }
    if (!tourFramePort) {
      tourFramePort = tourChannel.port1
      tourFramePort.onmessage = handlePortMessages
    }
    tourProps.tourIframe.contentWindow.postMessage(':)', '*', [tourChannel.port2])

    setTimeout(() => {
      tourFramePort.postMessage({
        url: window.location.href,
        type: 'set_url'
      })

      if (tour) {
        tour.key = key
        tourFramePort.postMessage({
          v: JSON.stringify(tour),
          type: 'set_tour'
        })
      }

      startTour({ previewMode })
    }, 100)
  })
}

const startTour = ({ previewMode }) => {
  if (tourAction) {
    let ta = {}
    try {
      ta = JSON.parse(tourAction)
    } catch (e) {}
    if (ta.mode === 'preview') {
      if (tourFramePort) {
        // set preview mode in widget
        tourFramePort.postMessage({
          type: 'set_preview'
        })
      }
      tourPreview(ta.index)
    } else {
      if (tourFramePort) {
        tourFramePort.postMessage({
          action: tourAction,
          type: 'tour_action'
        })
      }
    }
    window.sessionStorage.removeItem('engage_tour_action')
  }

  if (previewMode || (!tourProps.tourAuthorMode && !tourAction)) {
    if (tourFramePort) {
      // set preview mode on app
      tourFramePort.postMessage({
        type: 'set_preview'
      })
    }
    return startTourPreview()
  }
}

const logoBtnClicked = () => {
  stopTourPreview()
}

export {
  setTour,
  logoBtnClicked,
  addTourFrame,
  startTour,
  tourProps
}
